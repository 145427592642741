import { Column } from '@tanstack/react-table';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { FunctionComponent, useEffect, useState } from 'react';
import clsx from 'clsx';
import { greys, mainColors, RaptorTheme } from '../../../../styling/theme';
import { SearchProvider, useSearch } from '../context/SearchInputContext';
import { DebouncedInput } from './DebouncedInput';
import { LocalEditType } from './EditableCell';

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  input: {
    padding: '.5rem',
    border: '1px solid lightgrey',
    borderRadius: '.5rem',
    fontSize: '1.5rem',
  },
  selectOptions: {
    lineHeight: 1.5,
    fontWeight: 400,
    fontSize: '1.6rem',
  },

  optionsList: {
    listStyle: 'none',
    maxHeight: '500px',
    overflow: 'scroll',
    padding: 0,
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },

  buttonBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainButton: {
    fontSize: '1rem',
    textAlign: 'center',
    fontWeight: 500,
    color: greys.grey300,
    '&:hover,&:focus': {
      // backgroundColor: 'transparent',
      // color: mainColors.mainBlue_lighter
    },
    '& svg': {
      width: '1.5rem',
      height: '1.5rem',
      marginRight: '1rem',
    },
  },
  mainButtonNoFilter: {
    backgroundColor: 'transparent',
  },
  mainButtonWithFilters: {
    backgroundColor: mainColors.mainBlue,
    color: 'white',
  },
  mainPopper: {
    border: `1px solid #e1e4e8`,
    boxShadow: '0 8px 24px rgba(149, 157, 165, 0.2)',
    borderRadius: '1rem',
    width: '30rem',
    zIndex: theme.zIndex.modal,
    color: mainColors.mainBlue,
    backgroundColor: 'white',
    position: 'relative',
  },
  mainPopperHeader: {
    borderBottom: '1px solid #eaecef',
    padding: '1.5rem',
    fontWeight: 600,
    fontSize: '1.5rem',
  },
  sortContainer: {
    borderBottom: '1px solid #eaecef',
    padding: '0.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
  },
  sortButton: {
    padding: '0.5rem 1rem 0.5rem 0.5rem',
    fontSize: '1.2rem',
    display: 'flex',
    justifyContent: 'flex-start',
    '& svg': {
      width: '2rem',
      height: '2rem',
      margin: '0.5rem',
    },
  },
  inputBox: {
    borderBottom: '1px solid #eaecef',
    paddingBottom: '1.5rem',
  },
  inputSearch: {
    width: '100%',
    padding: '1.5rem',
    '& input': {
      borderRadius: '0.5rem',
      backgroundColor: '#fff',
      marginBottom: '1.5rem',
      border: `1px solid #eaecef`,
      fontSize: '1.5rem',
      '&:focus': {
        boxShadow: `0px 0px 0px 3px rgba(3, 102, 214, 0.3)`,
        borderColor: '#0366d6',
      },
    },
  },
  formGroup: {
    borderBottom: '1px solid #eaecef',
    padding: '1rem',
  },
  inputChooseAll: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem',
    },
  },
  label: {
    fontSize: '1.5rem',
  },

  listItem: {
    display: 'flex',
    color: 'black',
    fontSize: '1.5rem',
    justifyContent: 'center',
    fontWeight: 400,
    alignItems: 'center',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(39, 76, 119, 0.08)',
      cursor: 'pointer',
    },
    padding: '6px 16px',
    gap: '.15rem',
    borderRadius: 5,
    height: '36px',
  },
  selected: {
    background: 'rgba(39, 76, 119, 0.08)',
  },
}));

function FilterColumn({ column }: { column: Column<any, unknown> }) {
  const classes = useStyles();
  const columnFilterValue = column.getFilterValue();
  const { filterVariant } = column.columnDef.meta ?? {};

  return filterVariant === 'range' ? (
    <Box display="flex" gap={1}>
      <DebouncedInput
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ''}
        onChange={(value) => {
          if (!value) return;
          column.setFilterValue((old: [number, number]) => [value, old?.[1]]);
        }}
        placeholder={`Min`}
        className={classes.input}
      />
      <DebouncedInput
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ''}
        onChange={(value) => {
          if (!value) return;
          column.setFilterValue((old: [number, number]) => [old?.[0], value]);
        }}
        placeholder={`Max`}
        className={classes.input}
      />
    </Box>
  ) : filterVariant === 'select' ? (
    <SearchProvider>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <SearchFilterColumnItems />
        <SelectColumnItems column={column} />
      </div>
    </SearchProvider>
  ) : (
    <DebouncedInput
      className={classes.input}
      onChange={(value) => {
        if (!value) return;
        column.setFilterValue(value);
      }}
      placeholder={`Search...`}
      type="text"
      value={(columnFilterValue ?? '') as string}
    />
    // See faceted column filters example for datalist search suggestions
  );
}

export default FilterColumn;

function SearchFilterColumnItems() {
  const classes = useStyles();

  const { searchTerm, setSearchTerm } = useSearch();

  function handleChange(value: string | number) {
    setSearchTerm(value as string);
  }

  return (
    <DebouncedInput
      className={classes.input}
      onChange={handleChange}
      placeholder={`Search...`}
      type="text"
      value={searchTerm}
    />
  );
}

function SelectColumnItems({ column }: { column: Column<any, unknown> }) {
  const { searchTerm } = useSearch();
  const classes = useStyles();
  const [allSelected, setAllSelected] = useState(false);

  const columnFilterValue = column.getFilterValue() as string[] | number[];

  // TODO: Improve typing of the return filteredData

  const allColumnValuesArray = Array.from(
    column.getFacetedUniqueValues().keys(),
  );

  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  const processedData: string[] = allColumnValuesArray.reduce((acc, val) => {
    if (!val) return acc;

    let formattedVal = val;

    if (typeof val === 'number') {
      formattedVal = String(val);
    }

    if (isLocalEditType(formattedVal)) {
      formattedVal = formattedVal.updated;
    }

    const lowerCaseKey = formattedVal.toLowerCase();

    if (
      lowerCaseKey.includes(lowerCaseSearchTerm) ||
      columnFilterValue?.some((value: string | number) =>
        lowerCaseKey.includes(String(value).toLowerCase()),
      )
    ) {
      acc.push(formattedVal);
    }

    return acc;
  }, [] as string[]);

  const sortedAndLimitedData = processedData.sort((a, b) => a.localeCompare(b));

  const handleSelectAll = () => {
    // TODO: Improve state management, have uniform state to remove need for all selected useState hook
    setAllSelected((prev) => {
      if (prev) {
        column.setFilterValue([]);
        return false;
      } else {
        column.setFilterValue(allColumnValuesArray);
        return true;
      }
    });
  };

  return (
    <>
      <FormGroup className={classes.formGroup}>
        <FormControlLabel
          control={
            <Checkbox
              className={classes.inputChooseAll}
              checked={allSelected}
              onChange={handleSelectAll}
            />
          }
          label={<Typography className={classes.label}>Select All</Typography>}
        />
      </FormGroup>
      <ul className={classes.optionsList}>
        {sortedAndLimitedData.map((key, index) => (
          <OptionItem item={key} key={index} column={column} />
        ))}
      </ul>
    </>
  );
}

interface OptionItemProps {
  item: string;
  column: Column<any, unknown>;
}

const OptionItem: FunctionComponent<OptionItemProps> = ({ item, column }) => {
  const classes = useStyles();

  const columnFilterValue = column.getFilterValue() as (string | number)[];

  // TODO: Improve this hacky code
  let selected;
  if (columnFilterValue) {
    selected = columnFilterValue.includes(item);
  }

  return (
    <li
      className={clsx(classes.listItem, selected ? classes.selected : null)}
      onClick={() =>
        column?.setFilterValue((prev: string[]) => {
          if (!prev) return [item];
          if (prev && prev.includes(item)) {
            return prev.filter((el: string) => el !== item);
          } else {
            return [...prev, item];
          }
        })
      }
    >
      <DoneIcon style={{ opacity: selected ? 1 : 0, marginRight: 2 }} />
      <Box
        sx={{
          flexGrow: 1,
          '& span': {
            color: '#586069',
          },
        }}
      >
        {item}
      </Box>
      {selected && <CloseIcon />}
    </li>
  );
};

function isLocalEditType(obj: any): obj is LocalEditType {
  return (
    obj &&
    (typeof obj.old === 'string' || typeof obj.old === 'number') &&
    (typeof obj.updated === 'string' || typeof obj.updated === 'number')
  );
}
