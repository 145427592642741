import {
  ColumnDef,
  Row,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { RaptorTheme, greys, mainColors } from '../../../../../styling/theme';
import makeStyles from '@mui/styles/makeStyles';
import GridItem from '../../../../layout/GridComponents/GridItem';
import clsx from 'clsx';
import { ReactElement, useEffect, useState } from 'react';
import FilterNumber from './FilterNumber.component';
import FilterString from './FilterString.component';
import { cloneDeep, isEqual } from 'lodash';
import {
  FilterStateType,
  MinMaxStateType,
  MinMaxType,
  SortingStateType,
  SortingTypes,
} from './DataTypes';
import { roundToNDecimalPlacesReturnNumber } from '../../../../../utilities/numberFormatters';
import ExportButton, {
  ColumnIdToHeaderMapType,
} from '../../../../feedback/ExportButton';
import { useDispatch } from 'react-redux';
import { addComponentToPdfExport } from '../../../../../redux/pdfExport/actions';
import { PdfComponentType } from '../../../../../types/redux/pdfExports/pdfExportsStore';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import { Button } from '@mui/material';
import { fuzzyFilter } from '../../../../tables/ultraTable/utils/ultratable.utils';
import { customSortingFn } from '../../../../tables/ultraTable/utils/ultratable.utils';

type Position = {
  client_price: number;
  comparison_date: string;
  fund_id: string;
  fund_name: string;
  gm_name_long: string;
  instrument_type: string;
  isin: string;
  maturity_date: string;
  most_recent_nav_date: string;
  mr_position: number;
  reference_market_price: number;
  reference_mr_position: number;
  status: string;
};

const buildData = (originalData: any) => {
  if (!originalData || !originalData.data[0] || !originalData.data[0].length) {
    return [];
  } else {
    const builtData: Position[] = [];
    originalData.data[0].forEach((position: Position) => {
      builtData.push({
        client_price: roundToNDecimalPlacesReturnNumber(
          position.client_price,
          2,
        ),
        comparison_date: position.comparison_date,
        fund_id: position.fund_id,
        fund_name: position.fund_name,
        gm_name_long: position.gm_name_long,
        instrument_type: position.instrument_type,
        isin: position.isin,
        maturity_date: position.maturity_date,
        most_recent_nav_date: position.most_recent_nav_date,
        mr_position: roundToNDecimalPlacesReturnNumber(position.mr_position, 2),
        reference_market_price: roundToNDecimalPlacesReturnNumber(
          position.reference_market_price,
          2,
        ),
        reference_mr_position: roundToNDecimalPlacesReturnNumber(
          position.reference_mr_position,
          2,
        ),
        status: position.status,
      });
    });
    return builtData;
  }
};

const columns: ColumnDef<Position>[] = [
  {
    id: 'fund_id',
    accessorKey: 'fund_id',
    header: 'Fund Id',
  },
  {
    id: 'fund_name',
    accessorKey: 'fund_name',
    header: 'Fund Name',
  },
  {
    id: 'comparison_date',
    accessorKey: 'comparison_date',
    header: 'Lookback Date',
  },
  {
    id: 'most_recent_nav_date',
    accessorKey: 'most_recent_nav_date',
    header: 'Selected Date',
  },
  {
    id: 'gm_name_long',
    accessorKey: 'gm_name_long',
    header: 'Asset Name',
  },
  {
    id: 'isin',
    accessorKey: 'isin',
    header: 'ISIN',
  },
  {
    id: 'instrument_type',
    accessorKey: 'instrument_type',
    header: 'Instrument Type',
  },
  // {
  //   id: 'maturity_date',
  //   accessorKey: 'maturity_date',
  //   header: 'Maturity Date',
  // },
  {
    id: 'reference_market_price',
    accessorKey: 'reference_market_price',
    header: 'Lookback Price',
  },
  {
    id: 'client_price',
    accessorKey: 'client_price',
    header: 'Current Price',
  },
  {
    id: 'reference_mr_position',
    accessorKey: 'reference_mr_position',
    header: 'Lookback Position',
  },
  {
    id: 'mr_position',
    accessorKey: 'mr_position',
    header: 'Current Position',
  },
  {
    id: 'status',
    accessorKey: 'status',
    header: 'Status',
  },
];

// styling for the table
const useStyles = makeStyles<RaptorTheme>((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '3rem 3rem 0 3rem',
  },
  header: {
    ...theme.typography.h1,
    marginBottom: '3rem',
  },
  toolbarOptions: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  tableContainer: {
    // maxHeight: '70rem',
    overflow: 'auto',
    marginBottom: '2rem',
    width: '100%',
  },
  table: {
    tableLayout: 'fixed',
    paddingBottom: '1rem',
    borderCollapse: 'collapse',
    minWidth: '100%',
  },
  tableHead: {
    position: 'sticky',
  },
  tableBody: {
    paddingBottom: '2rem',
  },
  row: {
    display: 'flex',
    borderTop: `1px solid ${mainColors.lightGrey}`,
    '&:hover': {
      backgroundColor: mainColors.hoverOverWhite,
    },
    minHeight: '10rem',
    overflow: 'hidden',
  },
  hedgedRow: {
    backgroundColor: mainColors.veryFaintBlue,
    '&:hover': {
      backgroundColor: mainColors.hoverOverVeryFaintBlue,
    },
  },
  aggregateRow: {
    backgroundColor: mainColors.lightGrey,
  },
  headerRow: {
    display: 'flex',
    borderBottom: '2px solid grey',
  },
  cell: {
    ...theme.typography.h3,
    fontWeight: 500,
    textAlign: 'center',
    fontSize: '1.3rem',
    width: '100%',
    minWidth: '4rem',
    minHeight: '5rem',
    padding: '0.5rem 2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
  },
  widerCell: {
    minWidth: '12rem',
  },
  extraWideCell: {
    minWidth: '25rem',
  },
  switch: {
    display: 'flex',
    alignItems: 'center',
  },
  columnHead: {
    width: '100%',
    minWidth: '4rem',
    height: 'clamp(11rem, calc(25rem - 4.5vw), 17rem)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1.5rem',
    padding: '1rem',
    marginBottom: '1rem',
  },
  widerColumn: {
    minWidth: '12rem',
  },
  extraWideColumn: {
    minWidth: '25rem',
  },
  columnTitle: {
    fontWeight: 600,
    border: 'none',
    background: 'none',
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: '2rem',
    color: mainColors.mainBlue,
  },
  resetButton: {
    transition: 'width .2s',
    borderRadius: '.8rem',
    padding: '0.5 3rem',
    margin: '0 1rem',
    color: theme.palette.grey[800],
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  statusButtonsContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '1rem',
    padding: '0 1rem',
  },
  statusButton: {
    transition: 'width .2s',
    borderRadius: '.8rem',
    padding: '0.5 3rem',
    margin: '0 1rem',
    fontSize: '1.5rem',
    height: '3rem',
    color: 'white',
    fontWeight: 500,
    blockSize: 'fit-content',
    filter: `drop-shadow(0.1rem 0.1rem 0.1rem ${greys.grey400})`,
    '&:hover': {
      filter: 'grayscale(90%)',
      color: mainColors.mainBlue,
    },
  },
  activeStatusButton: {
    filter: 'grayscale(90%)',
    color: mainColors.mainBlue,
    border: `1px solid ${mainColors.mainBlue}`,
  },
  statusNoChange: {
    color: 'white',
    backgroundColor: mainColors.NA,
  },
  statusRemovedPosition: {
    color: 'white',
    backgroundColor: mainColors.Fail,
  },
  statusAlteredPosition: {
    color: 'white',
    backgroundColor: mainColors.Pass,
  },
  statusNewPosition: {
    color: 'white',
    backgroundColor: mainColors.fourthPurple,
  },
  statusMultiplePositions: {
    color: 'white',
    backgroundColor: mainColors.mainGold,
  },
  paginationControls: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '1rem 2rem 0 2rem',
  },
  paginationButtons: {
    display: 'flex',
  },
  pageToggleButton: {
    all: 'unset',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    border: 'none',
    color: 'white',
    padding: '0.5rem 1rem',
    margin: '0 0.5rem',
    cursor: 'pointer',
    borderRadius: '0.5rem',
    transition: 'background-color .2s ease-in-out',
    backgroundColor: mainColors.mainBlue,
    '&:hover': {
      backgroundColor: mainColors.hoverOverMainBlue,
    },
  },
  pageToggleButtonInactive: {
    pointerEvents: 'none',
    backgroundColor: mainColors.inactiveGrey,
  },
  pageCounts: {
    fontSize: '1.2rem',
    fontWeight: 600,
    margin: '1.5rem 1rem 0 1rem',
  },
  pageInput: {
    width: '5rem',
  },
  itemsPerPageSelect: {
    width: '5rem',
  },
}));

type TableProps<TData> = {
  originalData: any;
  positionDate: string;
};

const PositionsOversightTable = <T extends object>({
  originalData,
  positionDate,
}: TableProps<Position>): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // function to get all unique values from a column
  const getColumnUniqueVals = (columnId: string) => {
    const uniqueVals: string[] = [];
    Array.from(builtData).forEach((row: any) => {
      if (!uniqueVals.includes(row[columnId])) {
        uniqueVals.push(row[columnId]);
      }
    });
    if (typeof uniqueVals[0] === 'number') {
      return [];
    } else {
      return uniqueVals.sort();
    }
  };

  const [builtData] = useState<Position[]>(buildData(originalData));
  const [data, setData] = useState<Position[]>(builtData);

  const [unsortedData, setUnsortedData] = useState<Position[]>(data);
  const [currentSortedColumn, setCurrentSortedColumn] = useState<string>('');

  // setup filter states
  const originalFilterState: FilterStateType = {};
  Object.keys(builtData[0]).forEach((column) => {
    if (typeof builtData[0][column as keyof Position] === 'string') {
      originalFilterState[column] = getColumnUniqueVals(column);
    }
  });
  const [filterState, setFilterState] =
    useState<FilterStateType>(originalFilterState);

  // setup min/max states
  const originalMinMaxState: MinMaxStateType = {};
  Object.keys(builtData[0]).forEach((column) => {
    if (typeof builtData[0][column as keyof Position] === 'number') {
      originalMinMaxState[column] = ['', ''];
    }
  });
  const [minMax, setMinMax] = useState<MinMaxStateType>(originalMinMaxState);

  // setup sorting state
  const originalSortingState: SortingStateType = {};
  columns.forEach((col) => (originalSortingState[col.id as string] = ''));
  const [sortingState, setSortingState] =
    useState<SortingStateType>(originalSortingState);

  const table = useReactTable<Position>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    filterFns: {
      fuzzy: fuzzyFilter, //define as a filter function that can be used in column definitions
    },
    sortingFns: {
      customSortingFn: customSortingFn,
    },
  });

  useEffect(() => {
    table.setPageSize(50);
  }, [table]);

  // function to get an array of column ids
  const columnIds = (): string[] => {
    const ids: string[] = [];
    columns.forEach((col) => {
      ids.push(col.id as string);
    });
    return ids;
  };

  // function to get an object of coluumn id to header mappings
  const columnIdToHeaderMap = (): ColumnIdToHeaderMapType => {
    const mapping: ColumnIdToHeaderMapType = [];
    columns.forEach((col) => {
      mapping.push({ label: col.header as string, key: col.id as string });
    });
    return mapping;
  };

  // function to convert react-table row model data to export ready data
  const convertRowModelForExport = (rows: Row<Position>[]) =>
    rows.map((row) => row.original);

  // function to handle pdf export
  const tablePdfExportHandler = async () => {
    const renderColumns: any = [];
    columns.map((chosCol) => {
      renderColumns.push({
        header: chosCol.header,
        dataKey: chosCol.id,
        render: chosCol.cell,
      });
    });
    return {
      startY: 50,
      columns: renderColumns,
      body: convertRowModelForExport(table.getRowModel().rows),
    };
  };

  const [pdfIdentifier] = useState<string>('manco_positions_oversight');

  // when column selections update, dispatch changes in pdf export data to redux
  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: pdfIdentifier,
        handler: tablePdfExportHandler,
        type: PdfComponentType.TABLE,
        title: `Manco Positions Oversight`,
      }),
    );
  }, [table]);

  // function to get type of data in column
  const getTypeOfColumn = (columnId: string) => {
    if (builtData.length) {
      return typeof builtData[0][columnId as keyof Position];
    } else {
      return null;
    }
  };

  // update filters based on changes made in the popovers
  const handleFiltersChanged = (colId: string, newFilters: string[]) => {
    setFilterState((prev) => ({ ...prev, [colId]: newFilters }));
  };

  // update filters based on min max changes made in the popovers
  const handleMinMaxChanged = (
    colId: string,
    min: MinMaxType,
    max: MinMaxType,
  ) => {
    const temp = [min, max];
    setMinMax((prev) => ({ ...prev, [colId]: temp }));
  };

  // custom filtering code
  useEffect(() => {
    const filteredData: Position[] = [];
    Array.from(builtData).forEach((row: any) => {
      let rowOK = true;
      Object.keys(filterState).forEach((column) => {
        const found = filterState[column].some((el) => {
          if (typeof el === 'string' && typeof row[column] === 'string') {
            return el.toLowerCase() === row[column].toLowerCase();
          } else {
            return el === row[column];
          }
        });
        if (!found) {
          rowOK = false;
        }
      });
      Object.keys(minMax).forEach((column) => {
        if (minMax[column][0] !== '' && row[column] < minMax[column][0]) {
          rowOK = false;
        } else if (
          minMax[column][1] !== '' &&
          row[column] > minMax[column][1]
        ) {
          rowOK = false;
        }
      });
      if (rowOK) filteredData.push(row);
    });

    if (sortingState[currentSortedColumn] === 'Ascending') {
      setData(
        Array.from(filteredData).sort((first: any, second: any) => {
          let a = first[currentSortedColumn];
          let b = second[currentSortedColumn];
          if (getTypeOfColumn(currentSortedColumn) === 'string') {
            a = a.toLowerCase();
            b = b.toLowerCase();
          }
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        }),
      );
    } else if (sortingState[currentSortedColumn] === 'Descending') {
      setData(
        Array.from(filteredData).sort((first: any, second: any) => {
          let a = first[currentSortedColumn];
          let b = second[currentSortedColumn];
          if (getTypeOfColumn(currentSortedColumn) === 'string') {
            a = a.toLowerCase();
            b = b.toLowerCase();
          }
          if (a < b) {
            return 1;
          }
          if (a > b) {
            return -1;
          }
          return 0;
        }),
      );
    } else {
      setData(filteredData);
    }
  }, [filterState, minMax, sortingState]);

  // handler for when a colum sort is toggled
  // sorting a column first cancels any other sorts
  const handleSortChanged = (columnId: string) => {
    const order: SortingTypes[] = ['', 'Ascending', 'Descending'];
    const sortType = sortingState[columnId];
    const index = order.indexOf(sortType);
    const newState = cloneDeep(sortingState);
    if (columnId === currentSortedColumn) {
      if (index === 2) {
        newState[columnId] = order[0];
        setSortingState(newState);
      } else {
        newState[columnId] = order[index + 1];
        setSortingState(newState);
      }
    } else {
      setCurrentSortedColumn(columnId);
      Object.keys(newState).forEach((key) => (newState[key] = ''));
      newState[columnId] = 'Ascending';
      setSortingState(newState);
    }
  };

  // handler for when a colum is set to a certain sort value
  // sorting a column first cancels any other sorts
  const handleSortSet = (columnId: string, sortValue: SortingTypes) => {
    setCurrentSortedColumn(columnId);
    const newState = cloneDeep(sortingState);
    Object.keys(newState).forEach((key) => (newState[key] = ''));
    newState[columnId] = sortValue;
    setSortingState(newState);
  };

  const [resetFilters, setResetFilters] = useState<boolean>(false);

  const handleTableReset = () => {
    setActiveStatus(null);
    setData(builtData);
    setFilterState(originalFilterState);
    setMinMax(originalMinMaxState);
    setResetFilters(!resetFilters);
    setSortingState(originalSortingState);
  };

  // state of which status button should be highlighted, if any
  const [activeStatus, setActiveStatus] = useState<string | null>();

  // Filtering the table can be a little slow so we will show the snackbar to indicate that it is being worked on.
  // const { showAsyncSnackbar } = useSnackbar();
  // async function handleStatusChoice(choice: string) {
  //   await showAsyncSnackbar({
  //     startMessage: 'Applying your filter',
  //     successMessage: 'Filter Applied!',
  //     failureMessage: 'There was an issue with applying the filter',
  //     promiseToResolve: handleRequest(choice),
  //   });
  // }

  function handleStatusChoice(choice: string) {
    if (isEqual(activeStatus, choice)) {
      setActiveStatus(null);
      handleTableReset();
    } else if (choice === 'altered position') {
      setActiveStatus('altered position');
      handleFiltersChanged('status', ['altered position']);
    } else if (choice === 'multiple positions') {
      setActiveStatus('multiple positions');
      handleFiltersChanged('status', ['multiple positions']);
    } else if (choice === 'new position') {
      setActiveStatus('new position');
      handleFiltersChanged('status', ['new position']);
    } else if (choice === 'no change') {
      setActiveStatus('no change');
      handleFiltersChanged('status', ['no change']);
    } else if (choice === 'removed position') {
      setActiveStatus('removed position');
      handleFiltersChanged('status', ['removed position']);
    }
  }

  const handleGetStatusBoxStyle = (status: any) => {
    switch (status) {
      case 'no change':
        return classes.statusNoChange;
      case 'removed position':
        return classes.statusRemovedPosition;
      case 'altered position':
        return classes.statusAlteredPosition;
      case 'new position':
        return classes.statusNewPosition;
      case 'multiple positions':
        return classes.statusMultiplePositions;
      default:
        return null;
    }
  };

  const handleGetStatusButtonStyle = (status: string) => {
    if (status === activeStatus) {
      return clsx(classes.statusButton, classes.activeStatusButton);
    } else {
      return clsx(classes.statusButton, handleGetStatusBoxStyle(status));
    }
  };

  return (
    <GridItem xs={12} card>
      <div className={classes.toolbar}>
        <h2 className={classes.header}>Comparisons</h2>
        <div className={classes.statusButtonsContainer}>
          <Button
            aria-describedby={'New Position'}
            variant="text"
            disableElevation
            onClick={() => handleStatusChoice('new position')}
            className={handleGetStatusButtonStyle('new position')}
          >
            New Position
          </Button>
          <Button
            aria-describedby={'Altered Position'}
            variant="text"
            disableElevation
            onClick={() => handleStatusChoice('altered position')}
            className={handleGetStatusButtonStyle('altered position')}
          >
            Altered Position
          </Button>
          <Button
            aria-describedby={'Removed Position'}
            variant="text"
            disableElevation
            onClick={() => handleStatusChoice('removed position')}
            className={handleGetStatusButtonStyle('removed position')}
          >
            Removed Position
          </Button>
          <Button
            aria-describedby={'Multiple Positions'}
            variant="text"
            disableElevation
            onClick={() => handleStatusChoice('multiple positions')}
            className={handleGetStatusButtonStyle('multiple positions')}
          >
            Multiple Positions
          </Button>
          <Button
            aria-describedby={'No Change'}
            variant="text"
            disableElevation
            onClick={() => handleStatusChoice('no change')}
            className={handleGetStatusButtonStyle('no change')}
          >
            No Change
          </Button>
        </div>
        <div className={classes.toolbarOptions}>
          <Button
            aria-describedby={'reset'}
            variant="text"
            disableElevation
            onClick={handleTableReset}
            className={classes.resetButton}
          >
            Reset Filters
          </Button>
          <ExportButton
            exportData={convertRowModelForExport(table.getRowModel().rows)}
            pdfIdentifier={pdfIdentifier}
            fields={columnIds()}
            fieldsMap={columnIdToHeaderMap()}
            fileName={pdfIdentifier}
            selectedPositionDate={positionDate}
            allowPdfExport={true}
          />
        </div>
      </div>
      <GridItem xs={12} className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.tableHead}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className={classes.headerRow}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={
                      header.id === 'fund_id' || header.id === 'fund_name'
                        ? clsx(classes.columnHead, classes.widerColumn)
                        : header.id === 'gm_name_long'
                          ? clsx(classes.columnHead, classes.extraWideColumn)
                          : classes.columnHead
                    }
                  >
                    <div className={classes.columnTitle}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </div>
                    <div>
                      {getTypeOfColumn(header.id) === 'number' ? (
                        <FilterNumber
                          columnID={header.column.id}
                          originalMinMaxState={originalMinMaxState}
                          minMaxState={minMax}
                          onMinMaxChange={handleMinMaxChanged}
                          sortState={sortingState}
                          onSortChange={() =>
                            handleSortChanged(header.column.id)
                          }
                        />
                      ) : (
                        <FilterString
                          columnID={header.column.id}
                          originalFilterState={originalFilterState}
                          filterState={filterState}
                          onFilterChange={handleFiltersChanged}
                          sortState={sortingState}
                          onSortChange={() =>
                            handleSortChanged(header.column.id)
                          }
                        />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className={classes.tableBody}>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className={classes.row}>
                {row.getVisibleCells().map((cell) => {
                  return cell.id.includes('status') ? (
                    <td
                      key={cell.id}
                      className={clsx(
                        classes.cell,
                        handleGetStatusBoxStyle(cell.getValue()),
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ) : cell.id.includes('fund_id') ||
                    cell.id.includes('fund_name') ? (
                    <td
                      key={cell.id}
                      className={clsx(classes.cell, classes.widerCell)}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ) : cell.id.includes('gm_name_long') ? (
                    <td
                      key={cell.id}
                      className={clsx(classes.cell, classes.extraWideCell)}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ) : (
                    <td key={cell.id} className={classes.cell}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
        <div className={classes.paginationControls}>
          <div className={classes.paginationButtons}>
            <button
              className={
                !table.getCanPreviousPage()
                  ? clsx(
                      classes.pageToggleButton,
                      classes.pageToggleButtonInactive,
                    )
                  : classes.pageToggleButton
              }
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <KeyboardDoubleArrowLeft />
            </button>
            <button
              className={
                !table.getCanPreviousPage()
                  ? clsx(
                      classes.pageToggleButton,
                      classes.pageToggleButtonInactive,
                    )
                  : classes.pageToggleButton
              }
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <KeyboardArrowLeft />
            </button>
            <button
              className={
                !table.getCanNextPage()
                  ? clsx(
                      classes.pageToggleButton,
                      classes.pageToggleButtonInactive,
                    )
                  : classes.pageToggleButton
              }
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <KeyboardArrowRight />
            </button>
            <button
              className={
                !table.getCanNextPage()
                  ? clsx(
                      classes.pageToggleButton,
                      classes.pageToggleButtonInactive,
                    )
                  : classes.pageToggleButton
              }
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <KeyboardDoubleArrowRight />
            </button>
          </div>
          <div className={classes.pageCounts}>
            {`Page `}
            <input
              type="number"
              value={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className={classes.pageInput}
            />
            {` of ${table.getPageCount()}`}
          </div>
          {/* <div className={classes.pageCounts}>
            <select
              className={classes.itemsPerPageSelect}
              value={table.getState().pagination.pageSize}
              onChange={e => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            {` items per page`}
          </div> */}
        </div>
      </GridItem>
    </GridItem>
  );
};

export default PositionsOversightTable;
