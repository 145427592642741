import { rankItem } from '@tanstack/match-sorter-utils';
import { ColumnDef, FilterFn } from '@tanstack/react-table';
import { useUltraTable } from '../context/UltraTableContext';
import { GeneralFunctionType } from '../types/functions.types';
import {
  EditableCellWrapper,
  EditableFields,
  LocalEditType,
} from '../components/EditableCell';
import { UltraTablePresetConfig } from '../UltraTable';
import { VisibilityState } from '@tanstack/react-table';

// TODO: Darrach - You must fix to not work state updater just on blur but also on change

// Define the function to check if a column is editable
export const isCellEditable = (original: any, column: any): boolean => {
  return original?.editable_fields?.some(
    (field: any) => field.key_name === column.id,
  );
};

// Define a custom fuzzy filter function that will apply ranking info to rows (using match-sorter utils)
export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

/**
 * Custom sorting function for TanStack Table.
 *
 * This function sorts rows based on the value of a specified column. If the column value
 * has an 'updated' property, it uses that as the sorting value. Otherwise, it uses the
 * default column value.
 *
 *  rowA - The first row to compare.
 *  rowB - The second row to compare.
 *  columnId - The ID of the column to sort by.
 *  Returns 1 if rowA should come after rowB, -1 if rowA should come before rowB, and 0 if they are equal.
 */

export const customSortingFn = (rowA: any, rowB: any, columnId: any) => {
  const valueA = rowA.getValue(columnId);
  const valueB = rowB.getValue(columnId);

  const sortingValueA = valueA?.updated ?? valueA;
  const sortingValueB = valueB?.updated ?? valueB;

  // This will make it alphanumeric and case insensitive
  const stringValueA = sortingValueA.toString().toLowerCase();
  const stringValueB = sortingValueB.toString().toLowerCase();

  // Sort based on the determined sorting values
  return stringValueA === stringValueB
    ? 0
    : stringValueA > stringValueB
      ? 1
      : -1;
};

// This function is causing the issue with cell not having refetch value as old never changes
export const formatInitialValue = (value: any, returnOld = false) =>
  typeof value === 'object' && value !== null
    ? returnOld
      ? (value as LocalEditType).old
      : (value as LocalEditType).updated
    : value;

export const checkFormatValue = (value: any) =>
  typeof value === 'object' && value !== null
    ? (value as LocalEditType).old
    : value;

// // This function is causing the issue with cell not having refetch value as old never changes
// export const formatInitialValue = (value: any, returnOld = false) =>
//   typeof value === 'object' && value !== null ? JSON.stringify(value) : value;

// export const checkFormatValue = (value: any) =>
//   typeof value === 'object' && value !== null ? JSON.stringify(value) : value;

// This function creates the editable cells for the UltraTable

export const createDefaultColumn = <T extends EditableFields>(props: {
  fn?: GeneralFunctionType;
}): Partial<ColumnDef<T>> => {
  const { setUpdateLog } = useUltraTable();

  return {
    cell: ({ getValue, row: { original, index }, column, table, cell }) => {
      return (
        <EditableCellWrapper
          getValue={getValue}
          original={original}
          index={index}
          column={column}
          table={table}
          cell={cell}
          setUpdateLog={setUpdateLog}
          fn={props.fn}
        />
      );
    },
  };
};

// REFACTOR THIS COMPUTER CODE MR BARNEVELD
// This function creates the order of the columns in the UltraTable so that all editable columns are at the end
export const getColumnOrder = (
  columns: ColumnDef<any>[],
  exclusions: string[],
  isEditable: boolean | undefined,
): string[] => {
  const defaultOrder = exclusions || [];

  // Separate columns into those with editable set to true and others
  const editableColumns = columns.filter((c) => c.meta?.editable);
  const otherColumns = columns.filter((c) => !c.meta?.editable);

  // Map column IDs
  const otherColumnIds = otherColumns.map((c) => c.id!);
  const editableColumnIds = editableColumns.map((c) => c.id!);

  // If isEditable is selected, place editable columns at the end
  if (isEditable) {
    // Maintain the order from defaultOrder if it exists
    const orderedEditableColumnIds = editableColumnIds
      .filter((id) => !defaultOrder.includes(id))
      .sort((a, b) => {
        const indexA = defaultOrder.indexOf(a);
        const indexB = defaultOrder.indexOf(b);
        return indexA - indexB;
      });

    // Combine columns, keeping those in defaultOrder in their original positions
    const combinedOrder = defaultOrder.filter(
      (id) => otherColumnIds.includes(id) || editableColumnIds.includes(id),
    );
    const remainingOtherColumnIds = otherColumnIds.filter(
      (id) => !defaultOrder.includes(id),
    );

    return [
      ...combinedOrder,
      ...remainingOtherColumnIds,
      ...orderedEditableColumnIds,
    ];
  }

  // Default order without reordering
  return columns.map((c) => c.id!);
};

// This function creates the default visibility state for the UltraTable columns
export const createDefaultColumnVisibility = (
  columns: ColumnDef<any>[],
  presets?: UltraTablePresetConfig,
): VisibilityState => {
  const vis: VisibilityState = {};

  if (presets) {
    // Set to default visibility if included in presets.default.columns array
    columns.forEach((col) => {
      if (col.id !== undefined) {
        vis[col.id] = presets.default.columns.includes(col.id);
      }
    });
  } else {
    // Set all columns to true if no presets
    columns.forEach((col) => {
      if (col.id !== undefined) {
        vis[col.id] = true;
      }
    });
  }

  return vis;
};
