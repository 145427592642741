import { FunctionComponent } from 'react';
import { PostionUpdatePortalStyles } from '../styles/PostionUpdatePortal.styles';
import GridItem from '../../../../layout/GridComponents/GridItem';

const PortalWarning: FunctionComponent = () => {
  const classes = PostionUpdatePortalStyles();
  return (
    <GridItem xs={12} card>
      <div className={classes.tutorialBox}>
        <h1 className={classes.tutorialBoxTitle}>
          How to use the Position Update Portal
        </h1>
        <p className={classes.tutorialBoxContent}>
          This page allows you to update the positions of the fund. To update a
          position, follow these steps:
        </p>
        <ol className={classes.tutorialBoxContent}>
          <li>Click on the cell you would like to update</li>
          <li>Type in the new value</li>
          <li>Click the green tick to submit the changes</li>
        </ol>
        <p className={classes.tutorialBoxContent_warning}>
          <b>** Important to note **</b>
        </p>
        <p className={classes.tutorialBoxContent_warning}>
          Submitted changes will only affect calculations in the next run of the
          fund.
        </p>
        <p className={classes.tutorialBoxContent_warning}>
          If you would like to recalculate the current run using the changes you
          have made, please click the "Request Recalculation" button.
        </p>
      </div>
    </GridItem>
  );
};

export default PortalWarning;
